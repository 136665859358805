.config-form {

  &__brands {
    @include column-view(623px, 11px, 4, true);
  }

  &__brand {
    margin-bottom: em(11px);
    padding-top: em(90px);
    width: 22.5%;
  }

  &__row {
    margin-bottom: em(10px);
  }

  &__header {
    font-size: em(16px);
  }

  &__row_main {
    margin-bottom: em(25px);
    padding-bottom: em(25px);
    padding-left: em(25px);
    padding-right: em(25px);
  }

  &__wrapper {
    display: table;
    width: 100%;
  }

  &__wrapper_indent {
    padding-left: em(25px);
    .scrollable--parent {
      margin-left: 0;
    }
  }

  &__item {
    display: table-cell;
    padding-left: em(25px);
    text-align: right;
    vertical-align: middle;
    &:first-child {
      text-align: left;
      width: 60%;
    }
    &_left {
      text-align: left;
      &::after {
        content: none;
      }
    }
    &_30, &_30:first-child{
      width: 33.3%;
    }
    &_block {
      display: block;
      &_30 {
        width: 30.3%;
      }
    }
    &_small .config-form__input {
      display: inline-block;
      width: 6em;
    }
  }

  &__sub-row {
    display: table;
    & > *, & > *:first-child {
      display: table-cell;
      width: 25%;
    }
    & > *:last-child {
      width: 32%;
    }
    & > *.config-form__item_small {
      width: 1%;
    }
  }

  &__row_main &__item_left {
    padding-left: em(25px);
  }

  &__add-link {
    margin-bottom: em(22px);
    margin-left: em(15px);
  }

  &__checkbox {
    min-width: 5%;
  }

  &__list_table {
    //white-space: nowrap;
    word-spacing: -.3em;
  }

  &__list_table &__row {
    display: inline-block;
    padding: 0 .5em;
    vertical-align: top;
    width: 50%;
    &_wide {
      width: 100%;
    }
  }

  &__table {
    width: 100%;
    td {
      white-space: nowrap;
      &.config-form__wrap {
        white-space: normal;
      }
    }


  }


}