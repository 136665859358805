.service-inner {

  margin-top: em(30px);

  &__item {
    display: table;
    margin-bottom: em(30px);
    padding: 0;
    table-layout: fixed;
    width: 100%;
  }

  &__image {
    display: table-cell;
    padding: em(20px) 0 em(20px) em(20px);
    vertical-align: middle;
    width: 20%;
  }

  &__content {
    display: table-cell;
    padding: em(30px) em(45px) em(40px) em(20px);
    position: relative;
    vertical-align: middle;

    img {
      display: block;
      height: auto;
      margin: auto;
      max-height: 100%;
      max-width: 100%;
      width: auto;
    }
  }

  &__title {
    font-size: em(22px);
  }

  &__detail {
    margin: 0 em(40px) 0 0;
  }

  &__link {
    display: inline-block;
  }

  &__text {
    margin-bottom: em(24px);
  }


}