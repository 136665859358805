.search-result {

  &__item {
    margin-bottom: 2em;
  }

  &__info {
    margin-bottom: 1em;
    white-space: nowrap;
    word-spacing: -.3em;
  }
  &__image {
    display: inline-block;
    margin-bottom: 0;
    padding-top: 25%;
    vertical-align: middle;
    width: 25%;
  }
  &__title {
    display: inline-block;
    margin-bottom: 0;
    text-align: left;
    vertical-align: middle;
    white-space: normal;
    width: 100%;
    word-spacing: 0;
  }

  &__image + &__title {
    padding-left: em(40px, 18px);
    width: 75%;
  }

  &__control {
    &::after {
      clear: both;
      content: "";
      display: block;
    }
  }
  &__label {
    float: left;
    width: 52%;
  }
  &__button {
    float: right;
    min-width: 0;
    padding: em(15px) 0;
    width: 42%;

  }



}