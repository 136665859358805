.license {

  @include column-view(676px, 42px, 2, true);

  &__item {

  }

  &__image {
    width: 65%;
  }

  &__title {

  }

}