.menu {

  text-align: justify;

  .wrapper > & {
    margin-bottom: -1.3em;
  }

  &::after {
    content: "";
    display: inline-block;
    width: 100%;
  }

  &__item {
    color: $_gray;
    display: inline-block;
    font-weight: bold;
    transition: color .2s;
    vertical-align: middle;
    &:hover {
      color: $_ultra-marin;
    }
  }

  &__search {
    border: em(1px) solid $_blue;
    border-radius: em(2px);
    color: white;
    padding: .7em 1em;
    vertical-align: middle;
    width: em(144px);
    word-spacing: -.3em;
    input[type=text] {
      display: inline-block;
      vertical-align: middle;
      width: 75%;
      word-spacing: 0;
    }
    &:hover {
      color: white;
    }
  }

  &__loupe {
    cursor: pointer;
    display: inline-block;
    height: em(23px);
    @include loupe($_gray, em(21px));
    &::before {
      transition: border-color .2s;
    }
    &::after {
      transition: background .2s;
    }
    vertical-align: middle;
    width: 25%;
    &:hover {
      @include loupe($_ultra-marin, em(21px));
    }
  }
}