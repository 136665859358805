.footer {

  text-align: justify;
  padding-bottom: 0;

  &::after {
    content: "";
    display: inline-block;
    width: 100%;
  }

  &__logo {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
  }

  &__contact {
    display: inline-block;
    vertical-align: middle;
  }

  &__phone {
    margin-bottom: 0;
  }

  &__address {
    display: block;
    margin-bottom: 0;
  }

  &__copyright {
    display: inline-block;
    vertical-align: middle;
  }
}