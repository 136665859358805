.filter {

  background: none;
  margin: em(-10px) 0 0;
  padding: 0;

  &__wrapper {
    display: block;
    text-align: left;
  }

  &__closer, &__opener {
    display: none;
  }

  &__opener {

  }

  &__cross {

  }
  &__plus {

  }

  &__item {

    input {

    }
  }

  &__brand {
    background: $_gray-light;
    margin-right: 0;
    padding-left: em(20px);
    padding-right: em(20px);
  }


  &__text {
    margin-right: em(20px);
    &::before {

    }
    &::after {

    }
  }

  &__item input:checked + &__text {
    &::before {

    }
    &::after {

    }

  }

  &__item input:checked + &__brand {
    background: white;
  }


  &.closed {
    margin-bottom: em(20px);
  }

  &.closed &__wrapper {
    left: 0;
    opacity: 1;
    position: relative;

  }

  &.closed &__opener, &.closed &__closer {
    display: none;
  }



}