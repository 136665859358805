.stock {

  display: table;
  height: 100%;
  white-space: normal;
  width: 100%;

  &__content {
    padding-right: em(55px);
  }

  &__holder, &__wrapper {
    display: table-cell;
    height: 100%;
    width: 50%;
  }

  &__button {
    left: 50%;
    bottom: auto;
    margin-left: em(-20px);
    margin-top: em(-30px);
    padding: 1em 2em;
    right: auto;
    text-align: left;
    top: 50%;
  }
}
