.review {

  &__item {
    padding: 0 em(66px);
  }

  &__company {
    display: inline-block;
    padding-right: em(10px);
    vertical-align: top;
    width: 22%;
  }

  &__content {
    display: inline-block;
    vertical-align: top;
    width: 78%;
  }

  &__logo, &__person {
    display: block;
    text-align: left;
    width: 100%;
  }

  &__arrow {
    top: 50%;
    margin-top: em(-20px);
    &_left {
      left: em($indent/2);
      &::after {
        margin-left: em(4px);
      }
    }
    &_right {
      right: em($indent/2);
      &::after {
        margin-right: em(4px);
      }
    }
  }


}