.scrollable {

  &--parent {
    margin-left: 0;
    margin-right: 0;
    padding: 0;

    &_fade-right::after {
      content: none;
    }
  }
}