.search {

  white-space: nowrap;
  word-spacing: -.3em;

  input[type=text] {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
    text-align: left;
    width: 70%;
    word-spacing: 0;
  }

  &__button {
    display: inline-block;
    margin-left: em(-2px);
    min-width: 0;
    vertical-align: middle;
    width: 30%;
    word-spacing: 0;
  }

  &__loupe {

  }


}