.product-menu {

  &__head {
    &::after {
      margin: 1em 0 0;
    }
  }

  &__header {
    margin: 0 1em 1em 0;
    transition: color .2s;
    will-change: color;
    &:hover {
      color: $_ultra-marin;
    }
  }

  &__body {
    .wrapper > & {
      margin-bottom: em(-40px);
    }
    padding: 3em 0 0;
    word-spacing: -.3em;
  }


  &__list {
    display: inline-block;
    padding-right: 1em;
    margin-bottom: em(40px);
    vertical-align: top;
    width: 33.3%;
    word-spacing: 0;
  }
}