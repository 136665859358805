.ticket {

  &__info {
    display: table;
    width: 100%;
  }

  &__row {
    display: table-row;
    border-top: none;
    padding: 0;
  }

  &__header, &__value {
    border-top: em(2px, 14px) solid $_pale;
    display: table-cell;
    padding: em(7px, 14px) 0 em(9px, 14px);
  }

  &__header {
    padding-right: em(25px);
    white-space: nowrap;
  }

  &__value {
    width: 100%;
  }

  &__gear {

  }

}