.catalog {

  margin-bottom: -3em;
  margin-right: em(-58px);
  text-align: left;
  word-spacing: -.3em;

  &__item {
    background: white;
    display: inline-block;
    height: em(310px);
    margin-right: em(58px);
    padding: em(10px);
    transition: box-shadow .2s;
    vertical-align: bottom;
    white-space: nowrap;
    width: em(310px);
    word-spacing: 0;
    &::after {
      content: "";
      display: inline-block;
      height: 100%;
      vertical-align: bottom;
      width: 0;
    }
    &:hover {
      box-shadow: 0 0 em(20px) 0 rgba(black, .3);
    }

  }

  &__pusher {
    display: inline-block;
    vertical-align: bottom;
    white-space: normal;
    width: 100%;
  }


  &__image {
    display: block;
    height: auto;
    margin: 0 auto 2em;
    max-width: 100%;
    width: auto;
  }

  &__title {

    min-width: 0;
    width: 100%;

  }

}