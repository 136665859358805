.button {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  min-width: em(420px);

  &_inline {
    min-width: 0;
  }

  &--wrapper {
    margin-top: 2em;
    text-align: justify;
    &::after {
      content: "";
      display: inline-block;
      width: 100%;
    }
  }

  &--wrapper & {
    display: inline-block;
    margin: 0;
    min-width: 0;
    vertical-align: middle;
    width: 49%;
  }

  &_block {
    display: block;
    min-width: 0;
    width: 100%;
  }

  &_diff-1 {
    padding: 1em;
    min-width: 0;
  }

}