.callback {

  color: white;

  & > span {
    margin: 0 auto;
    width: 67%;
  }

  &__desc, &__phone, &__email {

    font-size: em(40px);
    margin-bottom: em(40px, 40px);
  }

  &__phone {
    font-size: em(38px);
    font-weight: normal;
    margin-bottom: em(4px, 38px);
  }

  &__email {
    font-size: em(26px);
    margin-bottom: em(52px, 26px);
  }

  input[type=text] {
    margin: 0 auto 2em;

  }

  img {
    max-width: 67%;
  }


  &__submit {
    min-width: 0;
  }

  &__agreement {
    width: 67%;
  }

}