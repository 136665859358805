.solution {

  &::after {
    clear: both;
    content: "";
    display: block;

  }

  &__header {
    float: left;
    font-size: em(30px);
    margin-right: 4%;
    width: 48%;
    &:nth-child(even) {
      margin-right: 0;
    }
    &_service {
      font-size: em(28px);
      padding: 0;
      text-align: center;
      width: 100%;
    }
  }

  &__list {
    font-size:em(15px, 30px);
  }


  &__service {
    clear: both;
    display: table;
    table-layout: fixed;
    width: 100%;
  }

  &__item {
    display: table-cell;
    padding: 0 em(0px) 0;
    vertical-align: bottom;
  }

  &__image {
    margin-bottom: 2em;
    width: 70%;
  }

}