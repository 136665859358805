.gt-mobile {
  display: block;
}
span.gt-mobile, a.gt-mobile {
  display: inline-block;
}

br.gt-mobile {
  display: inline;
}
.gt-tablet, .lt-tablet,
br.gt-tablet, br.lt-tablet,
span.gt-tablet, span.lt-tablet
{
  display: none;
}
.lt-desktop {
  display: block;
}
span.lt-desktop, br.lt-desktop {
  display: inline;
}

.e-tablet {
  display: block;
}

span.e-tablet, br.e-tablet {
  display: inline;
}