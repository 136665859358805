.company {

  &__header {
    display: inline-block;
    margin-bottom: 1.2em;
    position: relative
  }

  &__header &__list-link {
    bottom: .5em;
    display: inline;
    font-size: em(13px, 42px);
    margin-left: 1em;
    position: absolute;
    white-space: nowrap;
  }

  &__item {
    position: relative;
    width: auto;
    z-index: 1;
    &:hover {
      z-index: 2;
    }
  }

  &__holder {
    background: white;
    box-shadow: 0 0 em(20px) 0 rgba(black, .3);
    box-sizing: content-box;
    display: block;
    left: -999999px;
    min-width: 100%;
    opacity: 0;
    padding: em($indent);
    position: absolute;
    text-align: left;
    top: em(-$indent);
    transition: opacity .2s;
    white-space: nowrap;
    z-index: 1;
  }

  &__item:hover &__holder {
    left: em(-$indent);
    opacity: 1;
  }

  &__image {
    position: relative;
    z-index: 2;
    img {
      max-width: 200px;
    }
  }

  &__link {
    color: $_marin;
    display: block;
    margin: 1em 0;
    &:first-child {
      margin-top: 2em;
    }
    &:hover {
      text-decoration: underline;
    }
  }

  &__wrapper {
    padding: 0 4em;
  }

}