$media: 758px;
$indent: 15px;
$font-size: 15px;

@import "variables";
@import "tools";

body {
  font-size: $font-size;
  font-size: ($font-size*100/$media)*1vw;
}

@import "W:/Projects/Web/lnc/scss/758/_banner.scss";
@import "W:/Projects/Web/lnc/scss/758/_brand.scss";
@import "W:/Projects/Web/lnc/scss/758/_breadcrumbs.scss";
@import "W:/Projects/Web/lnc/scss/758/_button.scss";
@import "W:/Projects/Web/lnc/scss/758/_callback.scss";
@import "W:/Projects/Web/lnc/scss/758/_catalog.scss";
@import "W:/Projects/Web/lnc/scss/758/_char.scss";
@import "W:/Projects/Web/lnc/scss/758/_common.scss";
@import "W:/Projects/Web/lnc/scss/758/_company.scss";
@import "W:/Projects/Web/lnc/scss/758/_config-form.scss";
@import "W:/Projects/Web/lnc/scss/758/_configurator.scss";
@import "W:/Projects/Web/lnc/scss/758/_feature.scss";
@import "W:/Projects/Web/lnc/scss/758/_filter.scss";
@import "W:/Projects/Web/lnc/scss/758/_footer.scss";
@import "W:/Projects/Web/lnc/scss/758/_gallery.scss";
@import "W:/Projects/Web/lnc/scss/758/_license.scss";
@import "W:/Projects/Web/lnc/scss/758/_list-manager.scss";
@import "W:/Projects/Web/lnc/scss/758/_map.scss";
@import "W:/Projects/Web/lnc/scss/758/_menu.scss";
@import "W:/Projects/Web/lnc/scss/758/_model.scss";
@import "W:/Projects/Web/lnc/scss/758/_pagination.scss";
@import "W:/Projects/Web/lnc/scss/758/_popup-form.scss";
@import "W:/Projects/Web/lnc/scss/758/_product-menu.scss";
@import "W:/Projects/Web/lnc/scss/758/_project.scss";
@import "W:/Projects/Web/lnc/scss/758/_result.scss";
@import "W:/Projects/Web/lnc/scss/758/_review.scss";
@import "W:/Projects/Web/lnc/scss/758/_reviews.scss";
@import "W:/Projects/Web/lnc/scss/758/_scrollable.scss";
@import "W:/Projects/Web/lnc/scss/758/_search-result.scss";
@import "W:/Projects/Web/lnc/scss/758/_search.scss";
@import "W:/Projects/Web/lnc/scss/758/_service-inner.scss";
@import "W:/Projects/Web/lnc/scss/758/_service.scss";
@import "W:/Projects/Web/lnc/scss/758/_solution.scss";
@import "W:/Projects/Web/lnc/scss/758/_stock.scss";
@import "W:/Projects/Web/lnc/scss/758/_ticket.scss";
@import "W:/Projects/Web/lnc/scss/758/_wysiwyg.scss";
