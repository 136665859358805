.banner {
  height: em(496px);

  .wrapper {

    &::after {

    }
  }

  &__background {


  }

  &__logo {

  }

  &__content {

  }

  &__button {
    width: auto;
  }


  &__hpe {

    width: 100%;

    &_logo {
      left: 0;
      position: absolute;
      top: 0;
    }

    &_text {
      left: 50%;
      position: absolute;
      top: 0;
    }

    &_top {
      font-size: em(33px, 26px);
      font-weight: lighter;
      margin-top: 0;
    }
    &_middle, &_bottom {
      font-size: em(70px, 26px);
    }
    &_bottom {

    }
  }






}