.feature {


  &__menu {
    border-bottom: em(2px) solid $_marin;
    height: auto;
    &::after {
      content: none;
    }
  }

  &__wrapper {
    border: none;
    background: none;
    white-space: nowrap;
    word-spacing: -.3em;
  }

  &__menu_open &__tab.active,
  &__menu_open &__tab:hover,
  &__menu_open &__tab, &__tab, &__tab.active {
    background: none;
    border: em(2px) solid $_gray-middle;
    border-bottom: none;
    border-top-left-radius: em(6px);
    border-top-right-radius: em(6px);
    color: $_dark-blue;
    display: inline-block;
    margin-right: em(5px);
    padding: em(15px) em(26px) em(13px);
    text-align: center;
    vertical-align: bottom;
    &.hidden {
      display: none;
    }
  }


  &__menu &__tab.active {
    border-color: $_marin;
    color: $_marin;
  }

  &__tab_container {
    color: $_marin;
    position: relative;
  }

  &__container {
    background: white;
    border: em(2px) solid $_gray-middle;
    box-sizing: content-box;
    display: none;
    min-width: 100%;
    position: absolute;
    right: em(-2px);
    text-align: right;
    top: 100%;
  }

  &__container &__tab {
    box-sizing: border-box;
    font-size: 1em;
  }


  &__container.open {
    display: block;
  }

  &__menu &__tab_container &__tab {
    border: none;
    display: block;
    margin: 0;
    padding: 1em;
    &:hover {
      background: $_gray-light
    }
  }



}