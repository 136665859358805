.configurator {

  display: table;
  padding: em(20px) 0;
  table-layout: fixed;
  width: 100%;

  &__item {
    display: table-cell;
    margin-bottom: 0;
    padding-top: em(134px);
    position: relative;
    vertical-align: bottom;
  }

  &__list, &__item:last-child &__list {
    left: em(-2*$indent);
    right: em(-2*$indent);
    top: em(-80px);
    bottom: em(-120px);
  }

  &__image {
    position: absolute;
    top: 0;
    width: 100%;
  }

}