.reviews {


  &__item {
    margin-bottom: em(39px);
    padding: em(32px) em(27px);
  }




}