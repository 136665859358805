.project {

  &__item {
    text-align: justify;
    margin-bottom: em(26px);

    &::after {
      content: "";
      display: inline-block;
      width: 100%;
    }
    & > * {
      text-align: left;
    }
  }

  &__image {
    display: inline-block;
    height: em(170px);
    vertical-align: middle;
    width: 48%;
  }

  &__title {
    display: inline-block;
    font-size: em(20px);
    vertical-align: middle;
    width: 48%;
  }

  &__desc {
    clear: both;
    color: $_dark-pale;
    display: inline-block;
    font-size: em(13px);
    margin-bottom: em(20px);
    padding-left: em(26px);
    position: relative;
    text-align: justify;
    width: 100%;
    &::before {
      color: $_marin;
      content: "\2192";
      left: 0;
      position: absolute;
    }
    &_empty {
      margin-bottom: 0;
      padding-left: 0;
      &::before {
        content: none;
      }
    }
  }

  &__helper {
    display: inline-block;
  }


}
