.wysiwyg {


  ul li {

  }

  .blue {

  }

  h2 {
    clear: both;
    font-size: em(40px);
    margin-bottom: em(17px, 40px);
  }

  h3 {
    clear: both;
    font-size: em(30px);
    margin-bottom: em(17px, 30px);
  }

  * + h2 {
    margin-top: em(30px, 40px);
  }

  * + h3 {
    margin-top: em(40px, 30px);
  }

  img {
    display: inline-block;
    height: auto;
    width: auto;
    &.left {
      float: left;
      margin-right: em(22px);
    }
  }

  .button {
    min-width: 0;
  }

  ol {

  }

  ol ol {

  }

  ol ol ol {

  }

  ol li {

  }

  ol ol li {

  }

  ol ol ol li {

  }

  a:not([class]) {

  }

  table {

    th, td  {

    }
    th {

    }
    td {

    }
    tr:nth-child(even) td {

    }
  }

  form:not([class]) {

    text-align: justify;
    word-spacing: -.3em;

    &::after {
      content: "";
      display: inline-block;
      width: 100%;
    }

    .checkbox {
      &_independent {
        display: inline-block;
        text-align: left;
        vertical-align: top;
        width: 48%;
      }
    }

    label {
      display: inline-block;
      margin-bottom: em(12px);
      text-align: left;
      vertical-align: top;
      width: 48%;
      word-spacing: 0;
    }

    input, select, textarea {

    }
    textarea {

    }
    *[type="submit"] {
      margin: em(56px, 16px) auto 0;
      display: block;
      width: 100%;
      &:hover {

      }
    }

    .captcha img {
      width: em(176px, 16px);
    }


  }

}