.map {

  color: $_dark-blue-deep;

  &__content {
    height: em(668px);
  }

  &__desc {
    margin-bottom: 3em;
  }


  &__desc_traction &__header {

    &::before {

      margin-top: em(-19px);
      position: absolute;
      top: 50%;
      width: em(90px);
    }
  }




}