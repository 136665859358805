.pagination {

  margin: 0 auto;
  
  &__item {
    height: em(50px);
    width: em(50px);
  }

  &_width-2 {
    width: em(50px*2);
  }

  &_width-3 {
    width: em(50px*3);
  }

  &_width-4 {
    width: em(50px*4);
  }

  &_width-5 {
    width: em(50px*5);
  }

  &_width-6 {
    width: em(50px*6);
  }

  &_width-7 {
    width: em(50px*7);
  }

  &_width-8 {
    width: em(50px*8);
  }

  &_width-9 {
    width: em(50px*9);
  }

  &_width-10 {
    width: em(50px*10);
  }
  
}