.list-manager {
  & .pagination {
    float: left;
    margin-top: em(15px);
    width: 50%;
  }

  & .page-width--wrapper {
    float: right;
    margin-right: em(4px);
  }

  &::after {
    clear: both;
    content: "";
    display: block;
  }
}