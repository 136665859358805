.result {

  padding-top: 0;

  &__arrow {
    top: em(170px);
  }

  &__item {
    padding-left: 20%;
    padding-right: 20%;
  }

  &__image {
    padding-top: 40%;
    width: 40%;
    &_logo {
      display: inline-block;
      margin: 5%;
      padding-top: 10%;
      width: 20%;
    }
  }

  &__block {
    &_manufacturer {
      &::after {
        height: em(320px);
      }
    }
  }


}