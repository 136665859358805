.service {

  &__item {
    border: 2px solid $_gray-light;
    border-top: none;
    border-collapse: collapse;
    border-spacing: 0;
    display: table;
    margin-top: 0;
    table-layout: fixed;
    width: 100%;
  }

  &__item:first-child {
    border-top: 2px solid $_gray-light;
  }

  &__image--wrapper {
    display: table-cell;
    padding: em(30px) em(16px) em(30px) 0;
    vertical-align: top;
    width: auto;
  }

  &__image {
    margin: em(10px) auto;
  }

  &__content {
    border: em(2px) solid $_gray-light;
    border-left: none;
    border-top: none;
    display: table-cell;
    padding: em(30px) em(16px) em(30px) 0;
    vertical-align: top;
    width: 61%;
  }

  &__header {
    font-size: em(28px);
    margin-bottom: .5em;
  }

  &__detail {
    float: none;
  }

  /*&__header {
    color: $_marin;
    font-family: $openSans;
    font-size: em(22px);
    line-height: 1.3em;
    margin-bottom: .8em;
  }

  &__list {
    margin-bottom: 1em;
  }

  &__link {
    color: $_dark-blue-deep;
    display: block;
    font-size: em(14px);
    line-height: 1.2em;
    margin: .5em 0;
    &:hover {
      text-decoration: underline;
    }
  }

  &__detail {
    display: block;
    float: right;
    font-size: em(13px);
    padding: em(8px) em(15px);
  }*/

}