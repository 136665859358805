.popup-form {

  padding: 2em;
  text-align:justify;

  &::after {

  }

  &--wrapper {


    &::after {

    }
  }

  &__title {

  }

  &__row {
    width: 48%;
    &_wide {
      width: 100%;
    }
  }



  &__name {

  }

  input, textarea {

  }

  .button {
    display: block;
    margin: 2em auto 0;
    width: 70%;
  }



}