.gallery {

  border: em(2px) solid $_pale;
  margin-left: 0;
  margin-right: 0;
  padding: 0;

  &__view {

    border: none;
    margin: 20px auto 0;
    padding-top: 50%;
    width: 65%;

    & > img {

    }

    &_full {
      width: 100%;
    }

  }

  &__thumb {
    & > img {

    }
  }

  &__scroll {

  }

}