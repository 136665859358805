.model {
  margin-left: em(4px);
  word-spacing: -.3em;
  &__item {
    display: inline-block;
    margin-left: em(-2px);
    padding-top: 50%;
    vertical-align: top;
    width: 50%;
    word-spacing: -.3em;
  }
}